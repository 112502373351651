<script setup lang="ts">
import {
	PhHandbag,
	PhHeartStraight,
	PhWhatsappLogo,
} from '@phosphor-icons/vue';
import { useCountryCode } from '~/stores/country.ts';

const CountryStore = useCountryCode();
const { deliverToCountry } = storeToRefs(CountryStore);
const EstimatedShippingDate = defineAsyncComponent(
	() => import('./EstimatedShippingDate.vue'),
);
const ImageSliderNew = defineAsyncComponent(
	() => import('./ImageSliderNew.vue'),
);
const ImageSliderQuickAdd = defineAsyncComponent(
	() => import('./ImageSliderQuickAdd.vue'),
);
const ProductDescription = defineAsyncComponent(
	() => import('./ProductDescription.vue'),
);
const ProductForm = defineAsyncComponent(() => import('./ProductForm.vue'));
const ProductPrice = defineAsyncComponent(() => import('./ProductPrice.vue'));
const QuantityComponent = defineAsyncComponent(
	() => import('./QuantityComponent.vue'),
);
const ButtonLoader = defineAsyncComponent(() => import('../ButtonLoader.vue'));
const NotifyMe = defineAsyncComponent(
	() => import('../product-card/notify-me/NotifyMe.vue'),
);
import {
	Separator,
	Button,
	IconButton,
	useToast,
	HvcBadge,
	ExpressBadge,
	EidDeliveryBadge,
} from '@laam/ui/base';

import { DataSourceEnum } from '~/utils/enums';

import {
	getVariantShippingDaysDifference,
	isOriginalProductPrice,
	productImgAltTag,
} from '~/utils/helpers.ts';

const { $cathodeClient } = useNuxtApp();
const { $eventClient } = useNuxtApp();
import { useRoute } from 'vue-router';
const route = useRoute();

const { toast } = useToast();
import {
	productInjectKey,
	type VariantWithDate,
	type OptionsMap,
	type ProductOption,
	type ProductVariant,
} from '~/types/Product';
import { useCartStore } from '~/stores/cart';
import type { CustomFormTypes } from './size-chart/types';
import {
	useAddProductsToWishlist,
	useRemoveFromWishlist,
} from '~/data/wishlist';
import LazyImage from '../LazyImage.vue';
const AddToList = defineAsyncComponent(
	() => import('../wishlist/AddToList.vue'),
);

import { useCurrency } from '~/stores/currency';
import { useLoganStore } from '~/stores/logan';
import { getVariantHandlingDate } from '~/data/product.ts';
import type { ProductSchema } from '@laam/cms-shared';
import { FBConversion } from '~/utils/fbConversion.ts';
const RatingStars = defineAsyncComponent(() => import('./RatingStars.vue'));
const BrandRating = defineAsyncComponent(() => import('./BrandRating.vue'));
import { StatsigSingleton } from '@laam/statsig-client';
import ProductRewardsCard from '~/components/product-detail/ProductRewardsCard.vue';
import { useRewardsIndexQuery } from '~/data/rewards.ts';
import type { RewardsIndexResponse } from '~/types/Checkout.ts';
import { isRewardsEnabled } from '~/utils/featureFlags.ts';
import { INVENTORY } from '~/utils/constants';

interface ProductPageComponentProps {
	productData: ProductSchema;
	parent: 'product-page' | 'quick-view';
}

const props = defineProps<ProductPageComponentProps>();
provide(productInjectKey, props.productData);

const { storeType, store, addOns } = useGlobalStore();

const CurrencyStore = useCurrency();
const { getFormattedPrice } = CurrencyStore;
const { currencyCode, currencySymbol } = storeToRefs(CurrencyStore);
const LoganStore = useLoganStore();

const selectedVariantData = ref<ProductSchema['variants'][number] | null>(null);
const variantsWithDates = ref<VariantWithDate[]>([]);
const productDate = ref<string>('');
const selectedVariantPrice = ref<string>('');
const optionsMap = ref<OptionsMap>({});
const timelineCallCount = ref<number>(0);
const rewardsBalance = ref<number>(0);

const isVariantSelected = computed<Boolean>(() => {
	if (selectedVariantData.value === null) return false;
	else return true;
});

const productRewardsAmount = computed(() => {
	if (selectedVariantData.value) {
		return parseFloat(selectedVariantData.value.rewards ?? '0');
	} else {
		return parseFloat(props.productData.rewards ?? '0');
	}
});

provide('productRewardsAmount', productRewardsAmount);
const { favouritesMap, wishlists, favouriteProductsQueue } =
	storeToRefs(useWishlistStore());
const { isSignedIn } = storeToRefs(LoganStore);
const { addProductsToWishlist, isPending } = useAddProductsToWishlist();
const { data: rewardsIndexData } = useRewardsIndexQuery();

const rewardsEnabled = computed(() => {
	return isRewardsEnabled();
});

const { removeFromWishlist } = useRemoveFromWishlist();
const logan = useLoganStore();
provide('variantsWithDates', variantsWithDates);
provide('productDate', productDate);
provide('optionsMap', optionsMap);
provide('timelineCallCount', timelineCallCount);
provide('rewardsBalance', rewardsBalance);
provide('rewardsEnabled', rewardsEnabled);

const selectedQuantity = ref(1);

function getProductStatus() {
	if (
		(isVariantSelected.value &&
			selectedVariantData.value!.inventory_quantity <= 0) ||
		props.productData.price <= 0
	) {
		return 'out-of-stock';
	} else {
		return props.productData.stock_status;
	}
}

async function getProductId(id: string) {
	try {
		await navigator.clipboard.writeText(`${id}`);
		toast({
			title: 'Product ID copied to clipboard!',
			variant: 'info',
			duration: 3000,
		});
	} catch ($e) {
		console.error($e);
	}
}

function showButton(cta: string) {
	const buttonsArray = props.productData.meta.cta.split('+');

	if (buttonsArray.includes(cta)) {
		return true;
	} else return false;
}

const handlingDate = computed(() => {
	return selectedVariantData.value
		? selectedVariantData.value.variant_handling_date!
		: props.productData.product_handling_date;
});

const minimumVariantPrice = computed(() => {
	if (props.productData && props.productData.variants.length) {
		if (props.productData.variants[0]!.reseller_price) {
			return [...props.productData.variants].sort(
				(a, b): number =>
					parseFloat(a.reseller_price!) - parseFloat(b.reseller_price!),
			)[0]!.reseller_price;
		} else if (props.productData.variants[0]!.price) {
			return [...props.productData.variants].sort(
				(a, b): number => parseFloat(a.price!) - parseFloat(b.price!),
			)[0]!.price;
		} else {
			return [...props.productData.variants].sort(
				(a, b): number =>
					parseFloat(a.compare_at_price!) - parseFloat(b.compare_at_price!),
			)[0]!.compare_at_price;
		}
	}

	return '';
});

async function setSelectedVariant(variant: ProductSchema['variants'][number]) {
	selectedVariantData.value = variant;
}

const isHeaderVisible = inject('headerVisible') as Ref<boolean>;

const customForm = ref<CustomFormTypes>();
const showCustomFormError = ref(false);
const saveCustomform = (value: CustomFormTypes) => {
	customForm.value = value;
};

const CartStore = useCartStore();
async function handleAddToCart() {
	if (
		selectedVariantData.value &&
		selectedVariantData.value.inventory_quantity > 0
	) {
		const customSizeData = [];
		if (selectedVariantData.value.title === 'Custom Size') {
			if (customForm.value !== undefined) {
				for (let key in customForm.value) {
					const value = customForm.value[key as keyof typeof customForm.value];
					const obj: { key: string; value: string } = {
						key: '',
						value: '',
					};
					if (key.includes('units')) {
						key = 'units';
					}
					obj['key'] = key;
					obj['value'] = value.toString();
					customSizeData.push(obj);
				}
			} else {
				showCustomFormError.value = true;
				setTimeout(() => {
					showCustomFormError.value = false;
				}, 5000);
				return;
			}
		}
		let response;
		try {
			response = await CartStore.handleAddToCart(
				selectedVariantData.value,
				selectedQuantity.value,
				customSizeData,
			);

			if (response.code === 200) {
				$cathodeClient.queueEvent('add_to_cart', {
					id: String(selectedVariantData.value.shopify_id),
					source: window.location.href,
					sku: String(selectedVariantData.value.shopify_id),
					quantity: 1,
					price: selectedVariantPrice.value,
					currencyCode: currencyCode.value,
					data_source: `${eventsMetaData.value.dataSource}${eventsMetaData.value.version ? `- ${eventsMetaData.value.version}` : ''}`,
					clicked_from: eventsMetaData.value.clickedFrom.join(','),
					type: props.parent,
				});

				$eventClient.sendEvent('add-to-cart', {
					product_type: props.parent,
					quantity: selectedQuantity.value,
					shipping_date: productDate.value,
					product_handle: props.productData.handle,
					price: selectedVariantPrice.value,
					meta_data: {
						product: props.productData,
						selectedVariant: selectedVariantData.value!,
					},
					clicked_from: getAddToBagSource() as string,
					is_new_product: props.productData.tags.includes('Recently-Sellable'),
				});

				if (
					addOns?.['standard-meta-pixel'] &&
					addOns['standard-meta-pixel'] === true
				) {
					$eventClient.sendEvent('AddToCart', {
						event_name: 'AddToCart',
						content_ids: [props.productData.external_product_id],
						content_type: props.productData,
						contents: [
							{
								id: props.productData.external_product_id,
								quantity: selectedQuantity.value,
							},
						],
						currency: currencyCode.value,
						value: parseFloat(selectedVariantPrice.value),
					});
				}

				FBConversion.apiCall({
					event_name: 'add-to-cart',
					content: {
						value: selectedVariantPrice.value,
						currency: currencyCode.value,
					},
					custom_data: {
						content_name: props.productData.handle ?? null,
						content_category: props.productData.category ?? null,
						content_ids: selectedVariantData.value.shopify_id ?? null,
						content_type: 'product_group',
						num_items: selectedQuantity.value ?? null,
					},
				});
			} else {
				throw response;
			}
		} catch (error) {
			const err = error as { code: number; data: { message: string }[] };
			if (err.code! === 800) {
				toast({
					variant: 'error',
					title: err.data[0]!.message,
					duration: 3000,
				});
			}
		}
	}
}

function handleInvalidSubmit() {
	const element = document.getElementById('scrollToDiv');
	element?.scrollIntoView({ behavior: 'smooth', block: 'end' });
}

/* FOR WISHLIST */

const sheetOpen = ref(false);

const favouriteListId = computed(() => {
	return wishlists.value?.favouriteList.id;
});

const favouriteListName = computed(() => {
	return wishlists.value?.favouriteList.name;
});

const isFavourited = ref(false);

const isFavouriteDelayed = ref(false);

const handleFavorite = (e?: Event) => {
	e?.preventDefault();
	if (!isSignedIn.value) {
		favouriteProductsQueue.value.push(props.productData.shopify_id);
		isFavouriteDelayed.value = true;
		LoganStore.setRequiresSignIn();
		return;
	}
	if (!favouriteListId.value) {
		return;
	}
	if (!isFavourited.value) {
		isFavourited.value = true;
	}

	addProductsToWishlist({
		logan_id: logan.loganId,
		wishlist_id: favouriteListId.value,
		product_shopify_ids: [props.productData.shopify_id],
	});

	$eventClient.sendEvent('wishlist-add-product', {
		loganID: logan.loganId,
		productId: props.productData.shopify_id,
		wishlistID: favouriteListId.value,
		wishlistName: favouriteListName.value!,
		meta_data: {
			product: props.productData,
		},
	});

	toast({
		description: 'Item added to favourites',
		variant: 'info',
		class: 'py-md px-xl items-center gap-md lg:gap-xl',
		img: h(LazyImage, {
			src: props.productData.image.src,
			alt: props.productData.image.alt,
			width: 25,
			class: 'w-full',
		}),
		action: h(
			'span',
			{
				class: /*tw*/ 'text-sm font-semibold hover:cursor-pointer',
				onClick: () => (sheetOpen.value = true),
			},
			['Add to list'],
		),
	});
};

const handleUnfavorite = (e: Event) => {
	e.preventDefault();
	if (!favouriteListId.value) {
		return;
	}
	removeFromWishlist({
		wishlist: favouriteListId.value,
		products: [props.productData.shopify_id],
		wishlistHandle: wishlists.value!.favouriteList.handle,
	});

	favouritesMap.value.delete(props.productData.shopify_id.toString());
	isFavourited.value = false;

	$eventClient.sendEvent('wishlist-remove-product', {
		loganID: logan.loganId,
		productId: props.productData.shopify_id,
		wishlistID: favouriteListId.value,
	});

	toast({
		description: 'Item removed from favourites',
		variant: 'info',
		class: 'py-md px-xl items-center gap-md lg:gap-xl',
		img: h(LazyImage, {
			src: props.productData.image.src,
			alt: props.productData.image.alt,
			width: 25,
			class: 'w-full',
		}),
	});
};

//trigger to call all timelines of all variants on mount
const callingHandlingTimelines = async () => {
	props.productData.variants!.map(async (variant: ProductVariant) => {
		try {
			const res = await getVariantHandlingDate(variant.shopify_id);

			const splittedTitle = variant.title.split(' / ');
			const dateDiff = getVariantShippingDaysDifference(res!.handling_date);

			if (dateDiff > 1) {
				let index = 0;
				for (const optionName in optionsMap.value) {
					if (
						// eslint-disable-next-line
						optionsMap.value.hasOwnProperty(optionName) &&
						// eslint-disable-next-line
						optionsMap.value[optionName]!.hasOwnProperty(splittedTitle[index]!)
					) {
						optionsMap.value[optionName]![splittedTitle[index]!] = false;
					}

					index += 1;
				}
			}

			variantsWithDates.value = [
				...variantsWithDates.value,
				{
					variant: variant.title,
					shippingDate: res!.handling_date,
				},
			];

			timelineCallCount.value += 1;
		} catch (err) {
			throw new Error(`Error ${err} `);
		}
	});
};

const generateProductOptionsMap = () => {
	if (props.productData.options.length > 0) {
		const sortedOptions: ProductOption[] = [...props.productData.options].sort(
			(a, b): number => a.position - b.position,
		);
		for (let i = 0; i < sortedOptions.length; i++) {
			const { name, values } = sortedOptions[i]!;
			optionsMap.value![name] = values.reduce(
				(acc: { [key: string]: boolean }, value: string) => {
					acc[value] = true;
					return acc;
				},
				{},
			);
		}
	}
};

watch(favouriteProductsQueue, (q) => {
	if (!q.length && isFavouriteDelayed.value) {
		isFavouriteDelayed.value = false;
		handleFavorite();
	}
});

watch(
	rewardsIndexData,
	(data: RewardsIndexResponse | undefined) => {
		if (data) {
			rewardsBalance.value = data.balance;
		}
	},
	{
		immediate: true,
	},
);

onBeforeUnmount(() => {
	if (CartStore.viewBagTimeoutId) {
		clearTimeout(CartStore.viewBagTimeoutId);
		CartStore.viewBagTimeoutId = null;
		CartStore.showViewBag = false;
	}

	if (CartStore.addedToBagTimeoutId) {
		clearTimeout(CartStore.addedToBagTimeoutId);
		CartStore.addedToBagTimeoutId = null;
		CartStore.showAddedToBag = false;
	}
});
const brandRatingExperiment = ref(false);
onMounted(() => {
	if (
		isPending.value ||
		favouritesMap.value.has(props.productData.shopify_id.toString())
	) {
		isFavourited.value = true;
	}
	const exp = StatsigSingleton.getExperiment('brand_rating');
	brandRatingExperiment.value = (exp.value.Allowed &&
		props.productData.rating) as boolean;

	$cathodeClient.queueEvent('product_view', {
		clicked_from: eventsMetaData.value.clickedFrom.join(','),
		source: window.location.href,
		data: [
			{
				id: String(props.productData.shopify_id),
				inventory: props.productData.total_inventory_quantity,
			},
		],
		data_source: `${eventsMetaData.value.dataSource}${eventsMetaData.value.version ? ` - ${eventsMetaData.value.version}` : ''}`,
		type: props.parent,
	});

	$eventClient.sendEvent('product-view', {
		id: props.productData.shopify_id,
		source: window.location.href,
		currency: currencyCode.value,
		price: props.productData.price,
		imageUrl: props.productData.image.src,
		parent: props.parent,
		title: props.productData.title,
		status: props.productData.status,
		meta_data: {
			product: props.productData,
		},
	});

	FBConversion.apiCall({
		event_name: 'view-item',
		content: {
			value: minimumVariantPrice.value,
			currency: currencyCode.value,
		},
		custom_data: {
			content_name: props.productData.title,
			content_category: props.productData.category,
			content_ids: props.productData.shopify_id,
			content_type: 'product_group',
		},
	});

	generateProductOptionsMap();
	callingHandlingTimelines();
	linkWhatsapp.value = getWhastappLink();
});

const eventsMetaData = computed(() => {
	const queryParamsString =
		sessionStorage.getItem('cathodeQueryParamsObject') || '';

	let queryParams: {
		dataSource: string;
		clickedFrom: string[];
		version: string | null;
	} = {
		dataSource: DataSourceEnum.DIRECT,
		clickedFrom: [],
		version: null,
	};

	if (queryParamsString) queryParams = JSON.parse(queryParamsString);

	return queryParams;
});

const carouselTitle = inject('carouselTitle') as Ref<string | undefined>;

const getAddToBagSource = () => {
	if (props.parent === 'quick-view') {
		const page = route.name;
		if (page === 'nodes-handle') {
			return 'nodes';
		} else if (page === 'brands-handle') {
			return 'brands';
		} else if (page === 'products-handle') {
			return carouselTitle;
		} else if (page === 'index') {
			return 'women-homepage';
		} else if (page === 'men') {
			return 'men-homepage';
		} else if (page === 'kids') {
			return 'kids-homepage';
		}
	}
	return 'PDP';
};

watch(
	selectedVariantData,
	(newVal: ProductSchema['variants'][number] | null) => {
		if (newVal && Object.keys(newVal || {}).length > 0) {
			if (newVal.reseller_price) {
				selectedVariantPrice.value = newVal.reseller_price;
			} else if (newVal.price && newVal.price !== '') {
				selectedVariantPrice.value = newVal.price!;
			} else {
				selectedVariantPrice.value = newVal.compare_at_price!;
			}
		}

		return;
	},
);

const isOriginalVariantPrice = computed(() => {
	if (selectedVariantData.value) {
		return isOriginalProductPrice(
			parseFloat(selectedVariantData.value.compare_at_price || '0'),
			parseFloat(selectedVariantData.value.price),
		);
	}

	return false;
});

const tagsLowerCase = computed(() =>
	props.productData.tags.map((tag) => tag.toLowerCase()),
);

const linkWhatsapp = ref('');
const getWhastappLink = () => {
	const link = 'https://wa.me/';
	const contact = store.meta_data?.phone;
	const message = '"Hello, I want to consult about this product: "';
	const productHandle = ` ${window.location.href}`;
	return `${link}${contact}?text=${message}${productHandle}`;
};
const notifyMeProduct: Ref<{
	show: boolean;
	shopifyId: number;
	url: string;
	disabled: number[];
}> = inject('notifyMeProduct')!;

const disableNotifyMe = (shopifyId: number) => {
	notifyMeProduct.value.disabled.push(shopifyId);
};
const handleNotifyMe = () => {
	notifyMeProduct.value.show = true;
	notifyMeProduct.value.shopifyId = props.productData.shopify_id;
	notifyMeProduct.value.url = props.productData.handle;

	$eventClient.sendEvent('notify-me', {
		meta_data: {
			product: props.productData,
		},
	});
};

const headerVisible = inject('headerVisible') as Ref<boolean>;

const close: () => void = inject('close');
const isQuickAddPDP = inject('quickAddForm', ref(false));
const brandRatingRef = ref(null);
const parentDiv = ref(null);
const scrollToRating = async () => {
	await nextTick(); // Wait for the DOM to be updated

	if (!isMobileView() && brandRatingRef.value && parentDiv.value) {
		const parent = parentDiv.value;
		const child = brandRatingRef.value;

		// Calculate the offset of the child relative to the parent
		const offsetTop = child.offsetTop - parent.offsetTop;

		// Scroll the parent div to the calculated position
		parent.scrollTo({
			top: offsetTop,
			behavior: 'smooth',
		});
	} else {
		brandRatingRef.value.scrollIntoView({
			behavior: 'smooth',
			block: 'start',
		});
	}
};

const GlobalStore = useGlobalStore();
const imageAlt = computed(() => {
	if (props.productData.node_l1) {
		return productImgAltTag(props.productData);
	} else return `${props.productData.title} - ${props.productData.partner}`;
});

const testId = ref('');
watch(
	() => CartStore.cartId,
	() => {
		if (CartStore.cartId) {
			testId.value = 'add-to-bag-button';
		}
	},
	{ immediate: true },
);
const globalCutoffConfig = inject('globalCutoffConfig') as any;

const showEstimatedShippingOnEid = computed(() => {
	if (storeType !== 'MARKETPLACE') {
		return true;
	}
	return showEstimatedShipping(globalCutoffConfig, deliverToCountry);
});
</script>

<template>
	<div
		class="lg:grid lg:grid-cols-12 lg:gap-7xl temp__product-detail-container"
		:class="{
			'mt-0': isHeaderVisible && props.parent === 'product-page',
			'mb-2xl lg:mt-3xl': props.parent === 'product-page',
		}"
	>
		<NotifyMe
			v-if="notifyMeProduct?.show"
			@disable-notify-me="disableNotifyMe"
		/>
		<ImageSliderNew
			v-if="parent !== 'quick-view'"
			:media="props.productData.media"
			class="lg:col-span-6"
			:parent="parent"
			:alt="imageAlt"
		/>
		<ImageSliderQuickAdd
			v-else-if="parent === 'quick-view'"
			:media="props.productData.media"
			class="lg:col-span-6"
			:class="{
				'px-3xl': parent === 'quick-view' && isMobileView(),
			}"
			:parent="parent"
		/>

		<div
			ref="parentDiv"
			class="lg:col-span-6 gap-xl lg:gap-3xl flex flex-col"
			:class="{
				'relative overflow-y-auto max-h-[48.75rem]': !isMobileView(),
			}"
		>
			<!-- title, id, price -->
			<div class="p-3xl lg:p-none gap-xl flex flex-col">
				<!-- product title and brand name -->
				<h1 class="flex w-full gap-[5px]">
					<span class="text-md font-semibold text-gray-800 capitalize">
						{{ `${props.productData.title} -  ` }}
					</span>
					<span class="text-md font-semibold text-gray-800 capitalize">
						<NuxtLink
							v-if="GlobalStore.storeType !== 'OCTANE'"
							:to="`/brands/${props.productData.brand_handle?.toLowerCase()}`"
							class="underline"
						>
							{{ `${props.productData.brand || props.productData.partner}` }}
						</NuxtLink>
						<span v-else>
							{{ `${props.productData.brand || props.productData.partner}` }}
						</span>
					</span>
				</h1>

				<!-- external id -->
				<span class="text-sm text-gray-600">
					<Button
						v-if="props.productData.external_product_id !== null"
						variant="tertiary"
						class="external-product-id !px-none !py-none"
						@click="getProductId(props.productData.external_product_id)"
					>
						<span class="text-sm text-gray-600">
							ID:
							<span class="underline">
								{{ props.productData.external_product_id }}
							</span>
						</span>
					</Button>
				</span>

				<Separator class="lg:h-xxs w-full" />
				<ProductPrice
					:product-data="props.productData"
					:selected-variant-data="selectedVariantData"
				/>
			</div>

			<!-- badges -->
			<div class="gap-md px-3xl lg:px-none flex items-center">
				<HvcBadge
					v-if="
						LoganStore.isSignedIn &&
						LoganStore.isHvc &&
						props.productData.stock_status != INVENTORY.OOS
					"
				/>
				<ExpressBadge
					v-if="tagsLowerCase.includes('deliverydays_readytoship_rdlrg')"
				/>
				<EidDeliveryBadge
					v-if="tagsLowerCase.includes('deliver_eid delivery_rdlrg')"
				/>
			</div>

			<!-- with brand rating default height will be xxs -->
			<Separator
				v-if="brandRatingExperiment && props.productData.rating"
				class="pdp__separator"
				:class="{
					'!h-xxs py-xxs': brandRatingExperiment,
					'lg:!h-xxs': !brandRatingExperiment,
				}"
			/>

			<!-- Brand rating -->
			<div
				v-if="brandRatingExperiment && props.productData.rating"
				class="brand_rating-box gap-xl hover:cursor-pointer px-xl lg:px-none flex items-center w-full"
				@click="scrollToRating"
			>
				<div class="w-[44px] h-[44px] flex items-center justify-center">
					<LazyImage :src="props.productData.brand_logo!" alt="brand logo" />
				</div>
				<div class="gap-xs flex flex-col flex-1">
					<p class="text-sm font-semibold text-gray-900">
						{{ props.productData.brand }}
					</p>
					<div class="gap-xl flex">
						<div class="gap-md flex items-center">
							<p class="text-sm font-medium text-gray-700">
								{{ props.productData.rating.average_rating }}
							</p>
							<RatingStars :rating="props.productData.rating.average_rating" />
						</div>
						<p class="text-sm text-right text-gray-600">
							{{ props.productData.rating.total_ratings }} Ratings
						</p>
					</div>
				</div>
			</div>
			<div
				v-if="
					rewardsEnabled &&
					GlobalStore.storeType !== 'OCTANE' &&
					props.parent !== 'quick-view' &&
					!LoganStore.isSignedIn
				"
				class="gap-xl flex flex-col"
			>
				<Separator class="pdp__separator lg:!h-xxs" />

				<ProductRewardsCard />
			</div>

			<Separator class="pdp__separator" />

			<ProductForm
				:product-data="props.productData"
				:options="props.productData.options"
				:variants="props.productData.variants"
				:size-chart-id="props.productData.size_chart_id"
				:quantity="selectedQuantity"
				:selected-variant-data="selectedVariantData"
				:product-handle="props.productData.handle"
				:product-type="props.productData.category"
				:handling-date="handlingDate"
				:from-test="false"
				@selected-variant-data="setSelectedVariant"
				@on-success="handleAddToCart"
				@on-invalid-submit="handleInvalidSubmit"
				@save-customform="saveCustomform"
			/>

			<span
				v-if="getProductStatus() === 'out-of-stock'"
				class="info-out-of-stock text-sm text-gray-600"
			>
				Out of stock
			</span>
			<QuantityComponent
				v-else
				class="px-3xl lg:p-none"
				:product-data="props.productData"
				:selected-variant-data="selectedVariantData"
				:stock-status="getProductStatus()"
				@selected-quantity="(quantity: number) => (selectedQuantity = quantity)"
			/>

			<div
				v-if="
					rewardsEnabled &&
					GlobalStore.storeType !== 'OCTANE' &&
					props.parent === 'quick-view' &&
					!LoganStore.isSignedIn
				"
				class="gap-xl flex flex-col"
			>
				<Separator class="pdp__separator lg:!h-xxs" />

				<ProductRewardsCard />
				<Separator
					v-if="
						!isMobileView() &&
						parent === 'quick-view' &&
						brandRatingExperiment &&
						props.productData.rating
					"
					class="pdp__separator lg:!h-xxs"
				/>
			</div>

			<Separator class="pdp__separator lg:!h-xxs" />

			<NuxtLink
				v-if="getProductStatus() === 'out-of-stock'"
				:to="linkWhatsapp"
				class="pdp__cta-consultant p-3xl lg:p-none hidden w-full"
			>
				<Button
					variant="secondary"
					size="lg"
					class="w-full border [&_svg]:!fill-current border-success-600 text-success-600"
				>
					<PhWhatsappLogo
						size="20"
						weight="fill"
						color="#079455"
						class="mr-md"
					/>
					Consultant
				</Button>
				<Separator class="pdp__separator lg:!h-xxs" />
			</NuxtLink>

			<EstimatedShippingDate
				v-if="
					getProductStatus() !== 'out-of-stock' && showEstimatedShippingOnEid
				"
				class="px-3xl lg:p-none"
				:handling-date="handlingDate!"
				:default-date="props.productData.product_handling_date!"
				:selected-variant-data="selectedVariantData!"
			/>

			<!-- <Separator id="scrollToDiv" class="pdp__separator lg:!h-xxs" /> -->

			<div
				:data-parent="props.parent"
				:data-store-type="storeType"
				:data-header-visible="headerVisible"
				class="pdp-cta lg:static fixed left-0 right-0 z-10 transition-all"
			>
				<div
					v-if="getProductStatus() === 'out-of-stock'"
					class="pdp__cta-notify lg:hidden px-3xl py-xs border-y gap-md flex items-center justify-center w-full text-xs text-gray-800 bg-gray-100 border-gray-200 border-solid"
				>
					<div class="size-md bg-red-600 rounded-full" />
					<span class="font-semibold">Out of stock</span>
				</div>

				<div
					v-else-if="showEstimatedShippingOnEid"
					class="pdp__shipping-estimated-shipping-badge lg:hidden px-3xl py-xs border-y gap-xs flex justify-center w-full text-xs text-gray-800 bg-gray-100 border-gray-200 border-solid"
				>
					<div>
						<span class="text-gray-800">Estimated Shipping Date:</span>
						<span class="font-semibold">{{ productDate }}</span>
					</div>
				</div>

				<div class="gap-xl p-3xl lg:p-none flex bg-white">
					<IconButton
						v-if="GlobalStore.storeType !== 'OCTANE'"
						variant="secondary"
						size="lg"
						@click="
							(e: Event) =>
								!isFavourited ? handleFavorite(e) : handleUnfavorite(e)
						"
					>
						<PhHeartStraight
							:weight="!isFavourited ? 'regular' : 'fill'"
							:class="{
								'fill-black': !isFavourited,
								'!fill-error-600': isFavourited,
							}"
						/>
					</IconButton>

					<Button
						v-if="
							showButton('add-to-cart') &&
							!CartStore.showAddedToBag &&
							!CartStore.showViewBag &&
							getProductStatus() !== 'out-of-stock'
						"
						variant="primary"
						size="lg"
						:form="isQuickAddPDP ? 'quickAddForm' : 'productForm'"
						class="pdp__cta-add-to-bag w-full"
						:data-testid="testId"
					>
						<ButtonLoader v-if="CartStore.loading" />
						<p v-else>Add To Bag</p>
						<p v-if="selectedVariantPrice">
							{{
								`(${currencySymbol} ${getFormattedPrice(parseFloat(selectedVariantPrice) * selectedQuantity, !isOriginalVariantPrice)})`
							}}
						</p>
					</Button>
					<Button
						v-else-if="
							showButton('add-to-cart') && CartStore.showAddedToBag === true
						"
						variant="secondary"
						size="lg"
						class="w-full"
						disabled
					>
						Added To Bag
					</Button>

					<Button
						v-else-if="
							showButton('add-to-cart') && CartStore.showViewBag === true
						"
						variant="secondary"
						size="lg"
						:form="isQuickAddPDP ? 'quickAddForm' : 'productForm'"
						class="pdp__cta-view-bag w-full"
						@click="
							async () => {
								props.parent === 'quick-view' && close();
								await navigateTo('/cart');
							}
						"
					>
						<PhHandbag />
						<p>View Bag</p>
					</Button>
					<Button
						v-else-if="
							showButton('notify-me') || getProductStatus() === 'out-of-stock'
						"
						variant="primary"
						size="lg"
						class="w-full"
						:disabled="
							notifyMeProduct.disabled.includes(productData.shopify_id)
						"
						@click="handleNotifyMe"
					>
						<p>Notify Me</p>
					</Button>
				</div>
			</div>

			<Separator
				id="scrollToDiv"
				class="pdp__separator lg:hidden appearance-none"
			/>

			<!-- <h2
				class="product-description--title text-lg font-semibold text-gray-800"
			>
				Description
			</h2> -->
			<ProductDescription
				class="px-3xl lg:p-none"
				:brand-name="props.productData.brand"
				:product-type="props.productData.category"
				:description="props.productData.description"
			/>
			<Separator
				v-if="parent !== 'quick-view' || brandRatingExperiment"
				id="scrollToDiv"
				class="pdp__separator lg:hidden appearance-none"
			/>
			<div
				v-if="brandRatingExperiment && props.productData.rating"
				ref="brandRatingRef"
				class="p-3xl lg:p-none w-full"
			>
				<BrandRating
					:rating="props.productData.rating"
					:brand-name="props.productData.brand"
					:brand-logo="props.productData.brand_logo!"
					:brand-handle="props.productData.brand_handle!"
				/>
			</div>
			<!-- this will be shown with brand rating experiment only -->
			<Separator
				v-if="
					parent !== 'quick-view' && brandRatingExperiment && isMobileView()
				"
				class="pdp__separator lg:!h-xxs"
			/>
		</div>

		<AddToList
			v-if="wishlists && sheetOpen"
			:title="'Add to list'"
			:products-to-move="[props.productData.shopify_id]"
			:open="sheetOpen"
			@closed="sheetOpen = false"
		>
			<!-- eslint-disable-next-line vue/no-lone-template -->
			<template></template>
		</AddToList>
	</div>
</template>

<style scoped>
.pdp__separator {
	@apply !bg-gray-100 h-xl lg:h-xxs;
}
.pdp-cta[data-parent='quick-view'] {
	bottom: 0 !important;
}
.pdp-cta[data-store-type='OCTANE'] {
	bottom: 0 !important;
}
.pdp-cta[data-header-visible='true'] {
	bottom: 57px;
}
.pdp-cta[data-header-visible='false'] {
	bottom: 0;
}
</style>
